import { createStore } from "vuex";
import auth from "./modules/auth";
import users from "./modules/users";
import cases from "./modules/cases";
import courts from "./modules/courts";
import inmates from "./modules/inmates";
import custodialFacilities from "./modules/custodial-facilities";
import prosecutingAgencies from "./modules/prosecuting-agencies";
import loading from "./modules/loading";
import { httpService } from "@/services/http.service";
import { Graphs, Stat } from "@/models/statistics";

export default createStore({
  state: {
    pdf: null,
    statistics: {
      cases: {},
      inmates: {},
      custodialFacilities: {},
    },
    graphs: Graphs.prototype.init(),
    states: [],
  },
  getters: {},
  mutations: {
    SET_STATE: (state, { key, value }) => {
      Object.keys(value).forEach((key) => {
        value[key] = new Stat(value[key]);
      });
      state.statistics[key] = value;
    },
    SET_PDF: (state, pdfURL) => {
      state.pdf = pdfURL;
    },
    SET_STATES: (state, states) => {
      state.states = states;
    },
  },
  actions: {
    displayPDF: async ({ commit }, data) => {
      commit("SET_PDF", data);
    },
    clearPDF: async ({ commit }) => {
      commit("SET_PDF", null);
    },
    getStates: async ({ commit }) => {
      const response = await httpService.get(`state`, {
        params: { limit: 40 },
      });
      commit("SET_STATES", response.data.data.result);
    },
    getCaseStats: async ({ commit }) => {
      const response = await httpService.get("case/meta");
      const payload = { key: "cases", value: response.data.data };
      commit("SET_STATE", payload);
    },
    getInmateStats: async ({ commit }) => {
      const response = await httpService.get("inmate/meta");
      const payload = { key: "inmates", value: response.data.data };
      commit("SET_STATE", payload);
    },
    getCustodialStats: async ({ commit }) => {
      const response = await httpService.get("custodial-facility/meta");
      const payload = { key: "custodialFacilities", value: response.data.data };
      commit("SET_STATE", payload);
    },
    getStatistics: async ({ dispatch }) => {
      dispatch("getCaseStats");
      dispatch("getInmateStats");
      dispatch("getCustodialStats");
    },
  },
  modules: {
    auth,
    users,
    cases,
    courts,
    inmates,
    custodialFacilities,
    prosecutingAgencies,
    loading,
  },
});
