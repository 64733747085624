import { State } from "./state";

export class CustodialFacility {
  constructor(facility) {
    this.id = facility.id;
    this.name = facility.name;
    this.label = facility.label;
    this.state = new State(facility.state);
    this.capacity = facility.capacity;
    this.createdAt = new Date(facility.createdAt);
    this.updatedAt = new Date(facility.updatedAt);
  }

  get stateName() {
    return this.state.name;
  }
}
