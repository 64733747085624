<template>
  
    <!-- <SelectComponent
      v-if="user.isAdmin"
      title="Select an access level"
      v-model="accessLevel"
      :options="accessLevels"
      class="mb-8"
    /> -->
 
  <template v-for="item in navigation" :key="item.name">
    <div v-if="!item.children">
      <router-link
        :to="item.href"
        :class="[
          item.current
            ? 'bg-green-50 border-green-700 text-green-700'
            : 'border-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
          'group flex items-center px-3 py-2 text-sm font-medium border-l-4',
        ]"
      >
        <component
          :is="item.icon"
          :class="[
            item.current
              ? 'text-gray-500'
              : 'text-gray-400 group-hover:text-gray-500',
            'mr-3 flex-shrink-0 h-6 w-6',
          ]"
          aria-hidden="true"
        />
        {{ item.name }}
      </router-link>
    </div>
    <Disclosure as="div" v-else class="space-y-1" v-slot="{ open }">
      <DisclosureButton
        :class="[
          item.current
            ? 'bg-green-50 border-green-700 text-green-700'
            : 'border-transparent text-gray-600 hover:bg-green-50 hover:text-gray-900',
          'group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium border-l-4',
        ]"
      >
        <component
          :is="item.icon"
          class="
            mr-3
            flex-shrink-0
            h-6
            w-6
            text-gray-400
            group-hover:text-gray-500
          "
          aria-hidden="true"
        />
        <span class="flex-1">
          {{ item.name }}
        </span>
        <svg
          :class="[
            open ? 'text-gray-400 rotate-90' : 'text-gray-300',
            'ml-3 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400 transition-colors ease-in-out duration-150',
          ]"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
        </svg>
      </DisclosureButton>
      <DisclosurePanel class="space-y-1">
        <router-link
          :to="subItem.href"
          v-for="subItem in item.children"
          :key="subItem.href"
        >
          <DisclosureButton
            class="
              group
              text-left
              w-full
              flex
              items-center
              pl-11
              pr-2
              py-2
              text-sm
              font-medium
              text-gray-600
              rounded-md
              hover:text-gray-900 hover:bg-gray-50
            "
          >
            {{ subItem.name }}
          </DisclosureButton>
        </router-link>
      </DisclosurePanel>
    </Disclosure>
  </template>
</template>

<script>
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { AccessLevel } from "@/models/user";

import {
  AttorneyGeneralNavigation,
} from "./routes";

import { useStore } from "vuex";

export default defineComponent({
  name: "AppLayoutLinks",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
  },
  setup() {
    const store = useStore();
    const navigation = ref([]);
    const route = useRoute();
    const user = computed(() => store.state.auth.user);

    // Testing purposes only
    const accessLevels = ref(
      Object.entries(AccessLevel).map((accessLevel) => ({
        id: accessLevel[1],
        name: `${accessLevel[1].replaceAll("-", " ")}`,
      }))
    );

    // Change back to computed
    const accessLevel = ref(store.state.auth.user?.access?.accessLevel);

    const selectItem = (menuItem) => {
      if (menuItem.href && menuItem.href.length > 2) {
        navigation.value.map((item) => (item.current = false));
        const menuItemIndex = navigation.value.findIndex(
          (item) =>
            item.href == menuItem.href ||
            item.children?.find((child) => menuItem.href.includes(child.href))
        );
        if (menuItemIndex >= 0) navigation.value[menuItemIndex].current = true;
      }
    };

    onMounted(() => {
      switch (accessLevel.value) {
        case AccessLevel.PS:
          navigation.value = AttorneyGeneralNavigation;
          break;
        case AccessLevel.AGF:
          navigation.value = AttorneyGeneralNavigation;
          break;
        case AccessLevel.DIRECTOR:
          navigation.value = AttorneyGeneralNavigation;
          break;
        case AccessLevel.LAWYER:
          navigation.value = AttorneyGeneralNavigation;
          break;
        case AccessLevel.REGISTRAR:
          navigation.value = AttorneyGeneralNavigation;
          break;
        default:
          navigation.value = AttorneyGeneralNavigation;
          break;
      }
      selectItem({ href: route.path });
    });

    watch(
      () => route.path,
      async (routePath) => {
        selectItem({ href: routePath });
      }
    );

    // Testing purposes only
    watch(
      () => accessLevel.value,
      async (accessLevel) => {
        switch (accessLevel) {
          case AccessLevel.PS:
            navigation.value = AttorneyGeneralNavigation;
            break;
          case AccessLevel.AGF:
            navigation.value = AttorneyGeneralNavigation;
            break;
          case AccessLevel.DIRECTOR:
            navigation.value = AttorneyGeneralNavigation;
            break;
          case AccessLevel.LAWYER:
            navigation.value = AttorneyGeneralNavigation;
            break;
          case AccessLevel.REGISTRAR:
            navigation.value = AttorneyGeneralNavigation;
            break;
          default:
            navigation.value = AttorneyGeneralNavigation;
            break;
        }
      }
    );

    return {
      user,
      navigation,
      accessLevel,
      accessLevels,
    };
  },
});
</script>

