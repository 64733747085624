import { httpService } from "@/services/http.service";
import { Pagination } from "@/models/pagination";
import { CustodialFacility } from "@/models/custodialFacility";

const rootURL = "custodial-facility";

const state = {
  list: Pagination.prototype.createEmpty(),
  all: [],
};

const actions = {
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options });
    commit("SET_CUSTODIAL_FACILITIES", response.data.data);
  },
  getEvery: async ({ commit }) => {
    const response = await httpService.get(`${rootURL}`, {
      params: { limit: 1000 },
    });
    commit("SET_ALL", response.data.data.result);
  },
};

const mutations = {
  SET_CUSTODIAL_FACILITIES: (state, custodialFacilities) => {
    const custodialFacilitiesPagination = new Pagination(
      custodialFacilities,
      "custodialFacilities/getAll"
    );
    custodialFacilitiesPagination.result =
      custodialFacilitiesPagination.result.map(
        (custodialFacility) => new CustodialFacility(custodialFacility, true)
      );
    state.list = custodialFacilitiesPagination;
  },
  SET_ALL: (state, custodialFacilities) => {
    state.all = custodialFacilities.map(
      (custodialFacility) => new CustodialFacility(custodialFacility, true)
    );
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
