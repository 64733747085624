<template>
  <div
    :class="{ 'h-full w-full flex items-center justify-center': page }"
    v-if="loading"
  >
    <div :style="{ height: width, width: width }">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        style="
          margin: auto;
          background: none;
          display: block;
          shape-rendering: auto;
        "
        width="100%"
        height="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
      >
        <circle
          cx="50"
          cy="50"
          r="0"
          fill="none"
          stroke="#434343"
          stroke-width="4"
        >
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.4492753623188404s"
            values="0;35"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="0s"
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1.4492753623188404s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="0s"
          ></animate>
        </circle>
        <circle
          cx="50"
          cy="50"
          r="0"
          fill="none"
          stroke="#28292f"
          stroke-width="4"
        >
          <animate
            attributeName="r"
            repeatCount="indefinite"
            dur="1.4492753623188404s"
            values="0;35"
            keyTimes="0;1"
            keySplines="0 0.2 0.8 1"
            calcMode="spline"
            begin="-0.7246376811594202s"
          ></animate>
          <animate
            attributeName="opacity"
            repeatCount="indefinite"
            dur="1.4492753623188404s"
            values="1;0"
            keyTimes="0;1"
            keySplines="0.2 0 0.8 1"
            calcMode="spline"
            begin="-0.7246376811594202s"
          ></animate>
        </circle>
        <!-- [ldio] generated by https://loading.io/ -->
      </svg>
    </div>
  </div>
</template>

<script>
import { computed } from "@vue/runtime-core";
import { useStore } from "vuex";

export default {
  name: "LoadingComponent",
  props: {
    width: {
      type: String,
      default: "80px",
    },
    page: {
      type: Boolean,
      default: false,
    },
    load: {
      type: Boolean,
    },
  },
  setup(props) {
    const store = useStore();
    const loading = computed(() => {
      if (props.load) {
        return true;
      }
      return store.state.loading.loading;
    });

    return { loading };
  },
};
</script>

<style lang="scss" scoped>
</style>