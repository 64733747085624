import { CaseType } from "./case-file";

export class Statistics {
  constructor(statistics) {
    if (statistics.allCase) this.allCase = new Stat(statistics.allCase);

    if (statistics.activeCase)
      this.activeCase = new Stat(statistics.activeCase);

    if (statistics.civilCase) this.civilCase = new Stat(statistics.civilCase);

    if (statistics.appealCase)
      this.appealCase = new Stat(statistics.appealCase);

    if (statistics.criminalCase)
      this.criminalCase = new Stat(statistics.criminalCase);

    if (statistics.correctionalFacilities)
      this.correctionalFacilities = new Stat(statistics.correctionalFacilities);

    if (statistics.correctionalFacilitiesCapacity)
      this.correctionalFacilitiesCapacity = new Stat(
        statistics.correctionalFacilitiesCapacity
      );
    if (statistics.awaitingTrialInmates)
      this.awaitingTrialInmates = new Stat(statistics.awaitingTrialInmates);
    if (statistics.convictedInmates)
      this.convictedInmates = new Stat(statistics.convictedInmates);

    this.caseDistributionByDepartment = statistics.caseDistributionByDepartment
      ? statistics.caseDistributionByDepartment.map(
          (caseDistrubution) => new CaseDistrubution(caseDistrubution)
        )
      : [];
  }

  init() {
    return new Statistics({});
  }
}

export class Graphs {
  constructor(graphs) {
    this.inmatesByCapacity = graphs.inmatesByCapacity || [];
    this.inmatesByType = graphs.inmatesByType || [];
    this.inmatesByGender = graphs.inmatesByGender || [];
    this.inmatesByState = graphs.inmatesByState || [];
  }

  init() {
    return new Graphs({});
  }
}

export class Stat {
  constructor(stat) {
    this.count = stat.count !== undefined ? stat.count : stat || 0;
    this.difference = stat.difference || stat.percentage || 0;
    this.percentage = !!stat.percentage;
  }
}

class CaseDistrubution {
  constructor(caseDistrubution) {
    this.count = caseDistrubution.count;
    switch (caseDistrubution.type) {
      case CaseType.appeal.id:
        this.type = "Civil Appeal";
        break;
      case CaseType.civil.id:
        this.type = "Civil Litigation";
        break;
      case CaseType.criminal.id:
        this.type = "DPP";
        break;
    }
  }
}
