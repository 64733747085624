const metaWrapper = (to, from) => {
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && (r.meta.title || r.name));

  const previousNearestWithMeta = from.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) {
    document.title = `${nearestWithTitle.meta?.title || nearestWithTitle.name} | ${process.env.VUE_APP_ORGANIZATION_NAME}`;
  } else if (previousNearestWithMeta) {
    document.title = `${previousNearestWithMeta.meta?.title || previousNearestWithMeta.name} | ${process.env.VUE_APP_ORGANIZATION_NAME}`;
  }
}

export default metaWrapper