<template>
  <button :class="[
    variation == 'default' ? 'button-style' : '',
    variation == 'secondary' ? 'secondary-button-style' : '',
    small ? 'py-2 px-6 text-sm' : 'text-lg px-4 py-3 md:text-xl md:px-8',
    fullWidth ? 'w-full' : '',
    loader && loading ? 'bg-gray-200' : state,
    disabled ? 'disabled' : '',
  ]" :disabled="disabled || (loader && loading)" @click="$emit('click.stop')" :type="type ? 'button' : 'submit'">
    <span class="flex items-center text-white justify-center gap-x-1 truncate">
      <LoadingComponent width="30px" v-if="loader" />
      <slot v-if="!loading || !loader" />
    </span>
  </button>
</template>

<script>
import { useStore } from "vuex";
import { computed } from "@vue/runtime-core";

export default {
  name: "ButtonComponent",
  props: {
    state: {
      type: String,
      default: "primary",
    },
    loader: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: "default",
    },
  },
  setup() {
    const store = useStore();

    const loading = computed(() => {
      return store.state.loading.loading;
    });

    return { loading };
  },
};
</script>

<style scoped>
.button-style {
  @apply font-semibold select-none block w-full p-3 text-xl rounded-md focus:outline-none uppercase;
}

.secondary-button-style {
  @apply inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none;
}

.action {
  @apply bg-black !w-max rounded-md py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none;
}

.error {
  @apply bg-red-600 hover:bg-red-800;
}

.primary {
  @apply bg-green-700 hover:bg-green-900;
}

.warning {
  @apply bg-yellow-500;
}

.disabled {
  @apply bg-gray-500 text-gray-300 cursor-not-allowed;
}
</style>