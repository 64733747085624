<template>
  <div>
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="fixed inset-0 z-40 flex md:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div
            class="
              relative
              max-w-xs
              w-full
              bg-white
              pt-5
              pb-4
              flex-1 flex flex-col
            "
          >
            <TransitionChild
              as="template"
              enter="ease-in-out duration-300"
              enter-from="opacity-0"
              enter-to="opacity-100"
              leave="ease-in-out duration-300"
              leave-from="opacity-100"
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  class="
                    ml-1
                    flex
                    items-center
                    justify-center
                    h-10
                    w-10
                    rounded-full
                    focus:outline-none
                    focus:ring-2
                    focus:ring-inset
                    focus:ring-white
                  "
                  @click="sidebarOpen = false"
                >
                  <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-shrink-0 px-4 flex items-center">
              <img
                class="h-8 w-auto"
                src="@/assets/images/CoatOfArms.svg"
                alt="V-ACMS"
              /><span class="text-2xl text-green-700 font-bold pl-4"
                >V-ACMS</span
              >
            </div>
            <div class="mt-5 flex-1 h-0 overflow-y-auto">
              <nav class="px-2 space-y-1">
                <AppLayoutLinks />
              </nav>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
      <!-- Sidebar component-->
      <div
        class="
          flex flex-col flex-grow
          border-r border-gray-200
          pt-5
          pb-4
          bg-white
          overflow-y-auto
        "
      >
        <div class="flex items-center flex-shrink-0 px-4">
          <img
            class="h-8 w-auto"
            src="@/assets/images/CoatOfArms.svg"
            alt="V-ACMS"
          />
          <span class="text-2xl text-green-700 font-bold pl-4">V-ACMS</span>
        </div>
        <div class="mt-5 flex-grow flex flex-col">
          <nav class="flex-1 px-2 space-y-1 bg-white" aria-label="Sidebar">
            <AppLayoutLinks />
          </nav>
        </div>
      </div>
    </div>

    <div class="md:pl-64">
      <div
        class="max-w-6xl mx-auto flex flex-col px-8 lg:px-0"
        :class="{ 'overflow-hidden h-screen': noScroll }"
      >
        <div
          class="
            sticky
            top-0
            w-full
            z-10
            flex-shrink-0
            h-16
            bg-white
            border-b border-gray-200
            flex
          "
        >
          <button
            type="button"
            class="
              border-r border-gray-200
              px-4
              text-gray-500
              focus:outline-none
              focus:ring-2
              focus:ring-inset
              focus:ring-green-500
              md:hidden
            "
            @click="sidebarOpen = true"
          >
            <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
          </button>
          <div class="flex-1 flex justify-end px-4 md:px-0">
            <div class="flex items-center">
              <!-- Profile dropdown -->
              <Menu as="div" class="ml-3 relative">
                <div class="flex items-center space-x-2">
                  <p>
                    {{ user.fullName }}
                  </p>
                  <MenuButton
                    class="
                      max-w-xs
                      flex
                      items-center
                      text-sm
                      rounded-full
                      focus:outline-none
                      focus:ring-2
                      focus:ring-offset-2
                      focus:ring-green-500
                    "
                  >
                    <img
                      class="h-8 w-8 rounded-full"
                      src="@/assets/images/image.png"
                      alt=""
                    />
                  </MenuButton>
                </div>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="
                      origin-top-right
                      absolute
                      right-0
                      mt-2
                      w-48
                      rounded-md
                      shadow-lg
                      bg-white
                      ring-1 ring-black ring-opacity-5
                      py-1
                      focus:outline-none
                    "
                  >
                    <MenuItem v-slot="{ active }">
                      <router-link
                        to="/settings"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block py-2 px-4 text-sm text-gray-700',
                        ]"
                        >Settings
                      </router-link>
                    </MenuItem>
                    <MenuItem v-slot="{ active }">
                      <div
                        @click="logout"
                        :class="[
                          active ? 'bg-gray-100' : '',
                          'block py-2 px-4 text-sm text-gray-700 cursor-pointer',
                        ]"
                      >
                        Sign out
                      </div>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </div>
          </div>
        </div>

        <main class="flex-1 pt-8 pb-24 px-6 md:px-0">
          <BreadcrumbComponent />
          <PDFViewer />
          <router-view v-slot="{ Component }">
            <component :is="Component"></component>
          </router-view>
        </main>
      </div>

      <AppFooter />
    </div>
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter.vue";
import { ref, defineComponent, computed } from "vue";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import PDFViewer from "@/components/PdfViewer.vue";

import { MenuAlt2Icon, XIcon } from "@heroicons/vue/outline";
import AppLayoutLinks from "./Links/AppLayoutLinks.vue";
import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const userNavigation = [
  { name: "Your Profile", href: "#" },
  { name: "Settings", href: "/settings" },
  { name: "Sign out", href: "/" },
];

export default defineComponent({
  components: {
    AppFooter,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    MenuAlt2Icon,
    XIcon,
    PDFViewer,
    AppLayoutLinks,
    BreadcrumbComponent,
  },
  setup() {
    const sidebarOpen = ref(false);
    const store = useStore();
    const router = useRouter();

    const user = computed(() => {
      return store.state.auth.user;
    });

    const pdf = computed(() => {
      return store.state.pdf;
    });

    const logout = async () => {
      router.push({ name: "login" });
      await store.dispatch("auth/logout");
    };

    return {
      user,
      logout,
      noScroll: pdf,
      sidebarOpen,
      userNavigation,
    };
  },
});
</script>