<template>
  <div
    :class="[
      variation == 'default' ? 'space-y-1 w-full' : 'secondary-container-style',
    ]"
  >
    <label
      :for="id"
      :class="[
        variation == 'default'
          ? 'block text-sm font-medium text-gray-700'
          : 'secondary-label-style ',
      ]"
      >{{ title }}
    </label>
    <select
      :id="id"
      class="select-style"
      :value="modelValue"
      :required="required"
      :disabled="disabled"
      @input="$emit('update:modelValue', $event.target.value)"
    >
      <option
        v-for="option in selectOptions"
        :value="option.id"
        :key="option.id"
      >
        {{ option.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { onMounted, ref, watch } from "@vue/runtime-core";
export default {
  name: "SelectComponent",
  props: {
    modelValue: {
      required: false,
      default: "",
    },
    variation: {
      type: String,
      default: "default",
    },
    title: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const id = props.title.toLocaleLowerCase().replaceAll(" ", "-") + "---1";
    const selectOptions = ref([]);

    const generateID = (option) => {
      if (typeof option == "string" || typeof option == "number") {
        return option;
      } else if (typeof option == "object") {
        return option.id || option;
      }
    };

    onMounted(() => {
      selectOptions.value = props.options.map((option) => ({
        id: generateID(option),
        name: option.name || option.fullName || option,
      }));
    });

    watch(
      () => props.options,
      async (options) => {
        selectOptions.value = options.map((option) => ({
          id: generateID(option),
          name: option.name || option.fullName || option,
        }));
      }
    );

    return { id, selectOptions };
  },
};
</script>

<style scoped>
.select-style {
  @apply block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-gray-900 focus:border-gray-900 sm:text-sm;
}

.secondary-container-style {
  @apply sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5;
}

.secondary-label-style {
  @apply block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2;
}
</style>