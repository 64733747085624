import { Department } from "./department";

export class User {
  constructor(user) {
    this.id = user.id;
    this.email = user.email;
    this.isAdmin = user.isAdmin;
    this.lastName = user.lastName;
    this.firstName = user.firstName;
    this.phoneNumber = user.phoneNumber;
    if (user.access) this.access = new Access(user.access)
    this.createdAt = new Date(user.createdAt);
    this.updatedAt = new Date(user.updatedAt);
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`
  }
}

class Access {
  constructor(access) {
    this.id = access.id
    this.userId = access.userId
    this.accessLevel = access.accessLevel
    this.departmentId = access.department
    this.createdAt = new Date(access.createdAt)
    this.updatedAt = new Date(access.updatedAt)
    if (access.department) this.department = new Department(access.department)
  }

  get title() {
    return this.accessLevel.replaceAll("-", " ")
  }
}

export const AccessLevel = Object.freeze({
  AGF: 'attorney-general',
  PS: 'permanent-secretary',
  DIRECTOR: 'director',
  LAWYER: 'lawyer',
  REGISTRAR: 'registrar'
})