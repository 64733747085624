<template>
  <div
    v-if="loading"
    class="flex items-center justify-center h-screen bg-black"
  >
    <img
      src="@/assets/images/CoatOfArms.svg"
      class="w-32 pb-8 animate-pulse opacity-100"
    />
  </div>
  <component v-else :is="this.$route.meta.layout || layout">
    <slot />
  </component>
</template>

<script>
import { defineComponent, shallowRef } from "vue";
import BaseLayout from "./layouts/AppLayoutBase.vue";

export default defineComponent({
  name: "App",
  components: {},
  data: () => ({
    layout: shallowRef(BaseLayout),
    loading: true,
  }),
  mounted() {
    setTimeout(() => (this.loading = false), 1000);
  },
});
</script>

<style>
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: lightgray;
}

*::-webkit-scrollbar-thumb {
  background-color: #000000;
}
</style>
