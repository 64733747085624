import { User } from "@/models/user";

const _token = `v-acms::token`;
const _user = `v-acms::user`;

const _v1Token = "v-acms::v1-token";

const setToken = (token, version) => {
  const __token = version === "v1" ? _v1Token : _token;
  localStorage.setItem(__token, JSON.stringify(token));
};

const getToken = (version) => {
  const __token = version === "v1" ? _v1Token : _token;
  const token = localStorage.getItem(__token);
  if (token) {
    return JSON.parse(token);
  } else {
    return null;
  }
};

const removeToken = () => {
  localStorage.removeItem(_token);
  localStorage.removeItem(_v1Token);
};

const setUser = (user) => {
  localStorage.setItem(_user, JSON.stringify(user));
};

const getUser = () => {
  const user = localStorage.getItem(_user);
  if (user) {
    return new User(JSON.parse(user));
  } else {
    return null;
  }
};

const removeUser = () => {
  localStorage.removeItem(_user);
};

export default {
  setToken,
  getToken,
  removeToken,
  setUser,
  getUser,
  removeUser,
};
