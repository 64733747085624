import { createRouter, createWebHistory } from 'vue-router'
import authGuard from './guards/auth-guard'
import metaWrapper from './guards/meta-wrapper'
import { routes } from './routes'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  authGuard(to, from, next)
  metaWrapper(to, from)
})


export default router
