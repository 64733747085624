
import 'froala-editor/js/plugins.pkgd.min.js';
// Import Froala Editor css files.
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/froala_style.min.css';

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Icon } from "@iconify/vue";
import VueFroala from 'vue-froala-wysiwyg';

import DataDisplayComponent from "@/components/DataDisplayComponent.vue";
import BreadcrumbComponent from "@/components/BreadcrumbComponent.vue";
import PaginationComponent from "@/components/PaginationComponent.vue";
import LoadingComponent from "@/components/LoadingComponent.vue";
import SelectComponent from "@/components/SelectComponent.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import InputComponent from "@/components/InputComponent.vue";
import TableComponent from "@/components/TableComponent.vue";

import "./registerServiceWorker";
import "./assets/styles/index.css";

const app = createApp(App);

app.use(store);
app.use(router);
app.use(VueFroala);

app.component("DataDisplayComponent", DataDisplayComponent);
app.component("BreadcrumbComponent", BreadcrumbComponent);
app.component("PaginationComponent", PaginationComponent);
app.component("LoadingComponent", LoadingComponent);
app.component("SelectComponent", SelectComponent);
app.component("ButtonComponent", ButtonComponent);
app.component("InputComponent", InputComponent);
app.component("TableComponent", TableComponent);
app.component("IconComponent", Icon);

app.mount("#app");
