import { httpService } from "@/services/http.service";
import { Pagination } from "@/models/pagination";
import { ProsecutingAgency } from "@/models/prosecutingAgency";

const rootURL = "prosecuting-agency";

const state = {
  list: Pagination.prototype.createEmpty(),
  all: [],
};

const actions = {
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: options });
    commit("SET_PROSECUTING_AGENCIES", response.data.data);
  },
  getEvery: async ({ commit }) => {
    const response = await httpService.get(`${rootURL}`, {
      params: { limit: 1000 },
    });
    commit("SET_ALL", response.data.data.result);
  },
};

const mutations = {
  SET_PROSECUTING_AGENCIES: (state, prosecutingAgencies) => {
    const prosecutingAgenciesPagination = new Pagination(
      prosecutingAgencies,
      "prosecutingAgencies/getAll"
    );
    prosecutingAgenciesPagination.result =
      prosecutingAgenciesPagination.result.map(
        (prosecutingAgency) => new ProsecutingAgency(prosecutingAgency, true)
      );
    state.list = prosecutingAgenciesPagination;
  },
  SET_ALL: (state, prosecutingAgencies) => {
    state.all = prosecutingAgencies.map(
      (prosecutingAgency) => new ProsecutingAgency(prosecutingAgency, true)
    );
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
