<template>
  <div class="-my-2 overflow-x-auto">
    <InputComponent v-if="canSearch" title="Search" variation="search" v-model="search" @input="startSearch()"
      placeholder="Search..." />
    <div class="py-2 align-middle inline-block w-full">
      <div class=" border border-gray-200 rounded-lg overflow-x-auto">
        <table class="w-full divide-y divide-black">
          <thead class="bg-gray-100">
            <tr>
              <th v-for="head in headers" :key="head" scope="col" class="
                  px-4
                  py-3
                  text-left text-xs
                  font-bold
                  text-black
                  uppercase
                  tracking-wider
                ">
                {{ head.name }}
              </th>
              <th v-if="actions && actions.length > 0" class="px-4
                  py-3
                  text-left text-xs
                  font-bold
                  text-black
                  uppercase
                  tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody v-if="data.length > 0" class="bg-white divide-y divide-gray-200">
            <tr v-for="(datum, BackgroundStripe) in data" :key="datum.id"
              :class="BackgroundStripe % 2 === 0 ? 'bg-white' : 'bg-gray-50'">
              <td v-for="head in headers" :key="head"
                class="px-4 py-4 whitespace-nowrap text-xs text-gray-500 first:font-semibold first:text-gray-900">

                <div v-if="head.name == 'Image'" class="w-12 h-12 rounded-full overflow-hidden grid place-items-center">
                  <img :src="datum[head.key]" v-if="datum[head.key]" class="w-full h-full object-cover object-center" />
                  <p class="uppercase" v-else>{{ datum.firstName.charAt(0) }} {{ datum.lastName.charAt(0) }}</p>
                </div>
                <template v-else>
                  {{ datum[head.key] }}
                </template>
              </td>
              <td v-if="actions && actions.length > 0" class="px-4 py-4 whitespace-nowrap text-sm">
                <p v-for="action in actions" :key="action.name" @click="action.link(datum[action.key])"
                  class="text-green-700 hover:text-green-900 cursor-pointer font-bold">
                  {{ action.name }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <PaginationComponent :pagination="pagination" />
</template>

<script>
import { Pagination } from "@/models/pagination";
import { defineComponent, computed, ref } from "vue";
import { PaginationOptionsDTO } from "@/models/dtos/pagination-options-dto";
import { useStore } from "vuex";

export default defineComponent({
  name: "TableComponent",
  props: {
    pagination: {
      type: Pagination,
      required: true,
    },
    actions: {
      type: Array,
      required: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const store = useStore();
    const search = ref("");
    const data = computed(() => props.pagination.result);

    const canSearch = computed(() => props.searchable == undefined ? true : props.searchable);

    const startSearch = () => {
      if (search.value.length > 2) {
        store.dispatch(
          props.pagination.storeAction,
          new PaginationOptionsDTO({ search: search.value })
        );
      } else {
        store.dispatch(
          props.pagination.storeAction,
          new PaginationOptionsDTO({})
        );
      }
    };
    return { data, search, canSearch, startSearch };
  },
});
</script>