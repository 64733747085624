<template>
  <div class="w-full flex flex-col items-start space-y-2">
    <!-- <label class="xs" v-if="variation !== 'default'">
      {{ title }}
    </label> -->

    <div
      class="w-full"
      :class="[
        variation == 'default'
          ? 'flex items-center border-2 py-2 px-3 rounded-2xl'
          : '',
        variation == 'secondary' ? 'secondary-container-style' : '',
      ]"
    >
      <label class="secondary-label-style" v-if="variation == 'secondary'">
        {{ title }}
      </label>
      <div v-if="variation === 'search'">
        <!-- <IconComponent icon="akar-icons:search" /> -->
      </div>
      <svg
        v-if="type == 'email' && variation == 'default'"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
        />
      </svg>
      <svg
        v-if="type == 'password' && variation == 'default'"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-400"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          fill-rule="evenodd"
          d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
          clip-rule="evenodd"
        />
      </svg>
      <textarea
        class="input-style"
        autocomplete="off"
        v-if="type == 'textarea' && variation === 'default'"
        rows="5"
        :value="modelValue"
        :type="type"
        :name="inputName"
        :disabled="disabled"
        :required="required"
        :placeholder="placeholder || title"
        @input="$emit('update:modelValue', $event.target.value)"
      ></textarea>
      <input
        v-else
        :class="[
          variation == 'default' ? 'input-style' : 'secondary-input-style',
        ]"
        autocomplete="off"
        :min="min"
        :max="max"
        :type="type"
        :value="modelValue"
        :name="inputName"
        :disabled="disabled"
        :required="required"
        :minlength="minlength"
        :maxlength="maxlength"
        :pattern="pattern"
        :placeholder="variation == 'secondary' && !placeholder ? '' : placeholder || title"
        @input="$emit('update:modelValue', $event.target.value)"
        @keyup.enter="$emit('keyup.enter')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "InputComponent",
  props: {
    modelValue: {
      required: false,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    variation: {
      type: String,
      default: "default",
    },
    minlength: {
      type: Number,
      default: 0,
    },
    maxlength: {
      type: Number,
      required: false,
    },
    max: {
      type: String,
      required: false,
    },
    min: {
      type: String,
      required: false,
    },
    pattern: {
      type: String,
      required: false,
    },
  },
  setup(props) {
    const inputName = props.title.toLocaleLowerCase().replaceAll(" ", "-");
    return { inputName };
  },
};
</script>

<style scoped>
.input-style {
  @apply pl-2 outline-none border-none text-black bg-transparent w-full border-transparent focus:border-transparent focus:ring-0;
}

.secondary-input-style {
  @apply max-w-lg block w-full shadow-sm focus:ring-green-500 focus:border-green-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md;
}

.secondary-container-style {
  @apply sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5;
}

.secondary-label-style {
  @apply block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2;
}
</style>