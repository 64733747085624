import { Court } from "./court";
import { CustodialFacility } from "./custodialFacility";
import { ProsecutingAgency } from "./prosecutingAgency";

export class Inmate {
  constructor(inmate) {
    this.id = inmate.id;
    this.firstName = inmate.firstName;
    this.lastName = inmate.lastName;
    this.otherName = inmate.otherName;
    this.image = inmate.image;
    this.sex = inmate.sex;
    this.custodyNumber = inmate.custodyNumber;
    this.caseNumber = inmate.caseNumber;

    this.courtId = inmate.courtId;
    this.custodialFacilityId = inmate.custodialFacilityId;
    this.prosecutingAgencyId = inmate.prosecutingAgencyId;

    this.offense = inmate.offense;
    this.offenseInterpretation = inmate.offenseInterpretation;
    this.dateOfArraignment = inmate.dateOfArraignment
      ? new Date(inmate.dateOfArraignment).toISOString().split("T")[0]
      : null;
    this.dateOfAdmission = inmate.dateOfAdmission
      ? new Date(inmate.dateOfAdmission).toISOString().split("T")[0]
      : null;
    this.otherMeansOfId = inmate.otherMeansOfId;

    this.court = new Court(inmate.court);
    this.custodialFacility = new CustodialFacility(inmate.custodialFacility);
    this.prosecutingAgency = new ProsecutingAgency(inmate.prosecutingAgency);

    this.edr = inmate.edr;
    this.ldr = inmate.ldr;
    this.compensation = inmate.compensation;
    this.sentence = inmate.sentence;
    this.dateOfConviction = inmate.dateOfConviction
      ? new Date(inmate.dateOfConviction).toISOString().split("T")[0]
      : null;
    this.optionOfFine = inmate.optionOfFine;

    this.createdAt = new Date(inmate.createdAt);
    this.updatedAt = new Date(inmate.updatedAt);
  }

  get courtName() {
    return this.court.name;
  }

  get formattedDateOfArraignment() {
    return this.dateOfArraignment.toISOString().split("T")[0];
  }

  get formattedDateOfAdmission() {
    return this.dateOfAdmission.toLocaleDateString();
  }
}
