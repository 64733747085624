<template>
    <div class="py-6 fixed bottom-0 z-10 bg-white w-full md:pl-16">
  <p class="text-green-800 md:text-sm text-xs font-light">
    Copyright &#169; 2022, <span class="font-bold">Federal Ministry of Justice</span>, All Rights Reserved.
  </p>
</div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "AppFooter",
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
});



</script>

