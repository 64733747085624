<template>
  <div
    class="
      fixed
      w-screen
      h-screen
      top-0
      overflow-y-auto
      left-0
      bg-black bg-opacity-80
      z-50
      pt-10
    "
    v-if="pdf"
  >
    <div class="fixed right-5 top-5 cursor-pointer" @click="close()">
      <IconComponent icon="ph:x-duotone" color="white" />
    </div>
    <div
      class="max-w-screen-md mx-auto h-[90%] overflow-y-auto overflow-x-hidden"
    >
      <VuePdfEmbed
        ref="pdfRef"
        :source="pdf"
        :page="page"
        @loading-failed="loadFailed"
        @password-requested="handlePasswordRequest"
        @rendered="handleDocumentRender"
      />
      <div
        v-if="loadFail"
        class="text-white h-full w-full flex items-center justify-center"
      >
        <div class="flex flex-col items-center space-y-4">
          <IconComponent icon="fluent:document-error-24-regular" width="70" />
          <h4>Error loading document</h4>
          <ButtonComponent variation="secondary" state="error" @click="close()">
            CLOSE
          </ButtonComponent>
        </div>
      </div>
    </div>
    <div
      class="bg-white p-4 fixed bottom-0 w-screen text-black"
      v-if="!isLoading"
    >
      <div>
        <div class="flex items-center justify-center space-x-4 select-none">
          <button
            :class="{ hidden: page <= 1 }"
            :disabled="page <= 1"
            @click="page--"
          >
            ❮
          </button>
          <span> {{ page }} / {{ pageCount }} </span>
          <button
            :disabled="page >= pageCount"
            :class="{ hidden: page >= pageCount }"
            @click="page++"
          >
            ❯
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import VuePdfEmbed from "vue-pdf-embed";
import { useStore } from "vuex";

export default defineComponent({
  name: "PDFViewer",
  components: {
    VuePdfEmbed,
  },
  setup() {
    const page = ref(1);
    const pdfRef = ref();
    const store = useStore();
    const pageCount = ref(1);
    const loadFail = ref(false);
    const isLoading = ref(true);

    const pdf = computed(() => {
      return store.state.pdf;
    });

    const resetState = () => {
      loadFail.value = false;
      isLoading.value = true;
      pageCount.value = 1;
      page.value = 1;
    };

    const close = () => {
      store.dispatch("clearPDF");
      resetState();
    };

    const handleDocumentRender = () => {
      isLoading.value = false;
      pageCount.value = pdfRef.value.pageCount;
    };

    const loadFailed = () => {
      loadFail.value = true;
    };

    const handlePasswordRequest = (callback, retry) => {
      callback(prompt(retry ? "Enter password again" : "Enter password"));
    };

    return {
      pdf,
      page,
      pdfRef,
      loadFail,
      pageCount,
      isLoading,
      close,
      loadFailed,
      handleDocumentRender,
      handlePasswordRequest,
    };
  },
});
</script>