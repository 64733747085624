import { State } from "./state";

export class Court {
  constructor(court) {
    this.id = court.id;
    this.name = court.name;
    this.label = court.label;
    this.typeId = court.typeId;
    this.addressId = court.addressId;
    this.numberOfCourtRooms = court.numberOfCourtRooms;
    this.chiefRegistrar = court.chiefRegistrar;
    this.type = court.type ? new CourtType(court.type) : null;
    this.address = court.address ? new CourtAddress(court.address) : null;
    this.createdAt = new Date(court.createdAt);
    this.updatedAt = new Date(court.updatedAt);
  }

  get state() {
    return this.address?.stateName || "---";
  }

  get halfAddress() {
    return this.address?.halfAddress || "---";
  }
}

export class CourtType {
  constructor(courtType) {
    this.id = courtType.id;
    this.name = courtType.name;
    this.label = courtType.label.replaceAll(" ", "-");
    this.logoUrl = courtType.logoUrl;
    this.createdAt = new Date(courtType.createdAt);
    this.updatedAt = new Date(courtType.updatedAt);
  }
}

export class CourtAddress {
  constructor(courtAddress) {
    this.id = courtAddress.id;
    this.street = courtAddress.street;
    this.city = courtAddress.city;
    this.stateId = courtAddress.stateId;
    this.state = courtAddress.state ? new State(courtAddress.state) : null;
    this.createdAt = new Date(courtAddress.createdAt);
    this.updatedAt = new Date(courtAddress.updatedAt);
  }

  get halfAddress() {
    if (!this.street && !this.city) return "---";
    if (!this.street) return this.city;
    if (!this.city) return this.street;
    return `${this.street}, ${this.city}`;
  }

  get fullAddress() {
    return `${this.halfAddress == "---" ? "" : `${this.halfAddress},`} ${
      this.state?.name || ""
    } State`;
  }

  get name() {
    return this.fullAddress;
  }
  get stateName() {
    return this.state.name;
  }
}

class Slugged {
  constructor(slug, name) {
    this.slug = slug;
    this.name = name;
  }
}

export const CaseFileType = Object.freeze({
  caseFile: new Slugged("case-file", "Case File"),
  coverLetter: new Slugged("cover-letter", "Cover Letter"),
  supportingDocuments: new Slugged(
    "supporting-documents",
    "Supporting Documents"
  ),
});
