import { createToaster } from "@meforma/vue-toaster"

const toaster = createToaster({ position: 'bottom-left', max: 4 })

const info = (message) => {
  toaster.info(message)
}

const success = (message) => {
  toaster.success(message)
}

const error = (message) => {
  toaster.error(message)
}

const warning = (message) => {
  toaster.warning(message)
}

export default { info, success, error, warning }