import { User } from "@/models/user";
import authService from "@/services/auth.service";
import { httpService } from "@/services/http.service";
import { httpv1Service } from "@/services/httpv1.service";

const state = {
  user: authService.getUser(),
};

const actions = {
  login: async ({ dispatch }, data) => {
    const response = await httpService.post("auth/login", data);
    authService.setToken(response.data.data.token);
    await dispatch("verifyUser");
  },
  validateUser: async ({ dispatch }, data) => {
    const response = await httpService.post("auth/validate", data);
    await dispatch("authenticateOnV1", response.data.data);
    return response.data.data;
  },
  authenticateOnV1: async (context, data) => {
    const payload = {
      name: `${data.firstName} ${data.lastName}`,
      phone: data.phoneNumber.split("-")[1],
      email: data.email,
      password: "Hello123$",
      password_confirmation: "Hello123$",
      device_name: "iPhone 12 Pro Max",
    };

    try {
      const response = await httpv1Service.post("signup", payload, {
        params: { _silent_: true },
      });
      authService.setToken(response.data.data, "v1");
    } catch (error) {
      delete payload.name;
      delete payload.phone;
      delete payload.password_confirmation;
      const response = await httpv1Service.post("signin", payload);
      authService.setToken(response.data.data, "v1");
    }
  },
  forgotPassword: async (context, data) => {
    const response = await httpService.post("auth/forgot-password", data);
    return response.data.message;
  },
  changePassword: async (context, data) => {
    const response = await httpService.patch("auth/me", data);
    return response.data.messsage;
  },
  userVerification: async (context, data) => {
    const response = await httpService.post(
      "user/verification",
      { ...data.passwords },
      { params: { ...data.auth } }
    );
    return response.data.messsage;
  },
  verifyUser: async ({ commit }) => {
    const response = await httpService.get("auth/me", {
      params: { _silent_: true },
    });
    authService.setUser(response.data.data);
    commit("SET_USER", response.data.data);
  },
  logout: async ({ commit }) => {
    authService.removeUser();
    authService.removeToken();
    // commit('staff/CLEAR', null, { root: true })
    commit("CLEAR");
  },
};

const mutations = {
  SET_USER: (state, user) => {
    state.user = new User(user);
  },
  CLEAR: (state) => {
    state.user = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
