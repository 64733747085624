export class Pagination {
  constructor(pagination, store_action) {
    this.result = pagination?.result || pagination?.data || [];
    this.nextPage =
      pagination?.nextPage || pagination?.next_page_url?.split("page=")[1];
    this.previousPage =
      pagination?.previousPage || pagination?.prev_page_url?.split("page=")[1];
    this.currentPage = pagination?.currentPage || pagination?.current_page || 1;
    this.totalPages = pagination?.totalPages || pagination?.last_page || null;
    this.totalCount = pagination?.totalCount || pagination?.total || null;
    this.storeAction = store_action || "";
  }

  createEmpty() {
    return new Pagination({ data: [] });
  }
}
