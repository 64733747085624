import { httpService } from "@/services/http.service"
import { Pagination } from "@/models/pagination"
import { User } from "@/models/user";

const rootURL = 'user';

const state = {
  list: Pagination.prototype.createEmpty(),
  directors: Pagination.prototype.createEmpty(),
  lawyers: Pagination.prototype.createEmpty(),
  registrars: Pagination.prototype.createEmpty(),
}

const actions = {
  create: async ({ dispatch }, data) => {
    const response = await httpService.post(`${rootURL}`, data)
    dispatch("getAll")
    return response.data.message;
  },
  getAll: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: { ...options, accessLevel: 'registrar,lawyer,director,permanent-secretary,attorney-general' } })
    commit("SET_USERS", response.data.data)
  },
  getAllDirectors: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: { ...options, accessLevel: 'director' } })
    commit("SET_DIRECTORS", response.data.data)
  },
  getAllLawyers: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: { ...options, accessLevel: 'lawyer' } })
    commit("SET_LAWYERS", response.data.data)
  },
  getAllRegistrars: async ({ commit }, options) => {
    const response = await httpService.get(`${rootURL}`, { params: { ...options, accessLevel: 'registrar' } })
    commit("SET_REGISTRARS", response.data.data)
  },
  // get: async (context, id) => {
  //   const response = await httpService.get(`${rootURL}/${id}`)
  //   return new Court(response.data.data)
  // }
}

const mutations = {
  SET_USERS: (state, users) => {
    const pagination = new Pagination(users, "users/getAll")
    pagination.result = pagination.result.map((user) => new User(user))
    state.list = pagination
  },
  SET_DIRECTORS: (state, users) => {
    const pagination = new Pagination(users, "users/getAllDirectors")
    pagination.result = pagination.result.map((user) => new User(user))
    state.directors = pagination
  },
  SET_LAWYERS: (state, users) => {
    const pagination = new Pagination(users, "users/getAllLawyers")
    pagination.result = pagination.result.map((user) => new User(user))
    state.lawyers = pagination
  },
  SET_REGISTRARS: (state, users) => {
    const pagination = new Pagination(users, "users/getAllRegistrars")
    pagination.result = pagination.result.map((user) => new User(user))
    state.registrars = pagination
  },
  CLEAR: (state) => {
    state.list = Pagination.prototype.createEmpty()
    state.directors = Pagination.prototype.createEmpty()
    state.lawyers = Pagination.prototype.createEmpty()
    state.getAllRegistrars = Pagination.prototype.createEmpty()
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}