import moment from "moment"

const ddmmYYYY = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

const longDate = (date) => {
  return moment(date).format('dddd, DD MMM YYYY')
}


export default { ddmmYYYY, longDate }