<template>
  <div v-if="value" class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
    <dt class="text-sm font-semibold text-black">{{ label }}</dt>
    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center space-x-3">
      <p>{{ value }}</p>
      <router-link v-if="link" :to="link" class="text-green-700 hover:text-green-500 text-sm">View</router-link>
    </dd>
  </div>
</template>

<script>

export default {
  name: "DataDisplayComponent",
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    link: {
      type: String,
      default: null
    }
  },
};
</script>