<template>
  <div class="flex items-center justify-between mt-4 sm:mt-6" v-if="pagination.result.length > 0">
    <!-- Help text -->
    <span class="text-sm text-black font-light">
      Showing Page
      <span class="font-bold text-black">{{ pagination.currentPage }}</span> of
      <span class="font-bold text-black">{{ pagination.totalPages }}</span>
    </span>
    <div class="inline-flex mt-2 xs:mt-0 space-x-2">
      <!-- Buttons -->
      <button v-if="pagination.currentPage > 1" @click="previousPage" class="
          inline-flex
          items-center
          py-2
          px-4
          text-sm
          font-medium
          text-white
          bg-black
          rounded
          hover:bg-gray-900
        ">
        <svg aria-hidden="true" class="mr-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
            clip-rule="evenodd"></path>
        </svg>
        Prev
      </button>
      <button v-if="pagination.currentPage < pagination.totalPages" @click="nextPage" class="
          inline-flex
          items-center
          py-2
          px-4
          text-sm
          font-medium
          text-white
          bg-black
          rounded
          border-0 border-l border-white
          hover:bg-gray-900
        ">
        Next
        <svg aria-hidden="true" class="ml-2 w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
            d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
            clip-rule="evenodd"></path>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import { Pagination } from "@/models/pagination";
import { defineComponent } from "vue";
import { PaginationOptionsDTO } from "@/models/dtos/pagination-options-dto";
import { useStore } from "vuex";

export default defineComponent({
  name: "PaginationComponent",
  props: {
    pagination: {
      type: Pagination,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const previousPage = () => {
      store.dispatch(
        props.pagination.storeAction,
        new PaginationOptionsDTO({ page: props.pagination.currentPage - 1 })
      );
    };

    const nextPage = () => {
      store.dispatch(
        props.pagination.storeAction,
        new PaginationOptionsDTO({ page: props.pagination.currentPage + 1 })
      );
    };

    return { nextPage, previousPage };
  },
});
</script>