import dateFormatterService from "@/services/date-formatter.service";
import enumToTextService from "@/services/enum-to-text.service";
import { Court } from "./court";
import { User } from "./user";

export class CaseFile {
  constructor(caseFile, all) {
    this.id = caseFile.id;
    this.suitNumber = caseFile.suitNumber;
    this.initiatingParties = caseFile.initiatingParties;
    this.respondingParties = caseFile.respondingParties;
    this.type = enumToTextService.convertToText(caseFile.type);
    this.status = enumToTextService.convertToText(caseFile.status);
    this.courtId = caseFile.courtId;
    this.court = new Court(caseFile.court);

    this.parentCaseId = caseFile.parentCaseId;
    this.presidingJudge = caseFile.presidingJudge;
    this.originatingOrganisation = caseFile.originatingOrganisation;

    this.createdAt = new Date(caseFile.createdAt);
    this.updatedAt = new Date(caseFile.updatedAt);

    if (!all) {
      this.verdict = caseFile.verdict;
      this.documents = caseFile.documents ? caseFile.documents.map((document) => new Document(document)) : [];
      this.filings = caseFile.filings ? caseFile.filings.map((filing) => filing) : [];
      this.interlocutories = caseFile.interlocutories ? caseFile.interlocutories.map((interlocutory) => new CaseFile(interlocutory)) : [];
      this.notes = caseFile.notes ? caseFile.notes.map((note) => new Note(note)) : [];
      this.adjournments = caseFile.adjournments ? caseFile.adjournments.map((adjournment) => new Adjournment(adjournment)) : [];
      this.reports = caseFile.reports ? caseFile.reports.map((report) => new Report(report)) : [];
      this.verdicts = [
        this.interlocutories
          .map((interlocutory) => interlocutory.verdict ? interlocutory : null),
        this.verdict ? { ...this } : null].flat(1).filter((verdict) => verdict)
      this.relatedCases = [this.interlocutories.map((interlocutory) => ({ id: interlocutory.id, name: interlocutory.suitNumber })), { id: this.id, name: this.suitNumber }].flat(1)
    }
  }

  get initiatingPartiesList() {
    return this.initiatingParties.split(",")
  }

  get respondingPartiesList() {
    return this.respondingParties.split(",")
  }

  get numberOfInitiatingParties() {
    return this.initiatingParties.split(",").length
  }

  get numberOfRespondingParties() {
    return this.respondingParties.split(",").length
  }

  get initiatingPartyName() {
    switch (this.type) {
      case 'civil case':
        return 'Plaintiff';
      case 'appeal case':
        return 'Appeallant';
      case 'criminal case':
        return 'Prosecution';
      default:
        return 'Complainant';
    }
  }

  get respondingPartyName() {
    switch (this.type) {
      case 'civil case':
        return 'Defendant';
      case 'appeal case':
        return 'Respondent';
      case 'criminal case':
        return 'Defendant';
      default:
        return 'Defendant';
    }
  }
}

class Document {
  constructor(document) {
    this.id = document.id;
    this.caseId = document.caseId;
    this.path = document.path;
    this.type = enumToTextService.convertToText(document.type);
    this.createdAt = new Date(document.createdAt);
    this.updatedAt = new Date(document.updatedAt);
  }
}

class Report {
  constructor(report) {
    this.id = report.id;
    this.caseId = report.caseId;
    this.content = report.content;
    this.createdAt = new Date(report.createdAt);
    this.updatedAt = new Date(report.updatedAt);
  }

  get date() {
    return dateFormatterService.ddmmYYYY(this.createdAt)
  }
}

class Note {
  constructor(note) {
    this.id = note.id;
    this.from = new User(note.from)
    this.fromId = note.fromId;
    this.toId = note.toId;
    this.to = new User(note.to)
    this.content = note.content;
    this.createdAt = new Date(note.createdAt);
    this.updatedAt = new Date(note.updatedAt);
  }

  get date() {
    return dateFormatterService.ddmmYYYY(this.createdAt)
  }
}

class Adjournment {
  constructor(report) {
    this.id = report.id;
    this.caseId = report.caseId;
    this.dateAdjournedTo = report.dateAdjournedTo;
    this.reason = report.reason;
    this.createdAt = new Date(report.createdAt);
    this.updatedAt = new Date(report.updatedAt);
  }

  get date() {
    return dateFormatterService.ddmmYYYY(this.createdAt)
  }

  get newDate() {
    return dateFormatterService.longDate(this.dateAdjournedTo)
  }
}

class Slugged {
  constructor(id, name) {
    this.id = id;
    this.name = name || id.replaceAll("-", " ")
  }
}

export const CaseFileType = Object.freeze({
  caseFile: new Slugged('case-file', 'Case File'),
  coverLetter: new Slugged('cover-letter', 'Cover Letter'),
  supportingDocuments: new Slugged('supporting-documents', 'Supporting Documents'),
})

export const CaseType = Object.freeze({
  civil: new Slugged('civil-case', 'Civil Case'),
  appeal: new Slugged('appeal-case', 'Civil Appeal'),
  criminal: new Slugged('criminal-case', 'Criminal Case'),
})

export const CaseStatus = Object.freeze({
  pending: new Slugged('pending', 'Pending'),
  inCourt: new Slugged('in-court', 'In Court'),
  struckOut: new Slugged('struck-out', 'Struck Out'),
  verdict: new Slugged('verdict/judgement-passed', 'Verdict/Judgement Passed'),
  preliminaryHearing: new Slugged('preliminary-hearing', 'Preliminary Hearing'),
})