import { httpService } from "@/services/http.service";
import { Pagination } from "@/models/pagination";
import { Inmate } from "@/models/inmate";

const state = {
  awaitingTrialList: Pagination.prototype.createEmpty(),
  convictedList: Pagination.prototype.createEmpty(),
};

const actions = {
  createAwaitingTrial: async ({ dispatch }, data) => {
    const response = await httpService.post(`inmate/awaiting-trial`, data);
    dispatch("getAllAwaitingTrial");
    return response.data.message;
  },
  getAllAwaitingTrial: async ({ commit }, options) => {
    const response = await httpService.get(`inmate/awaiting-trial`, {
      params: options,
    });
    commit("SET_AWAITING_TRIAL", response.data.data);
  },
  getAwaitingTrialInmate: async (context, id) => {
    const response = await httpService.get(`inmate/awaiting-trial/${id}`);
    return new Inmate(response.data.data);
  },
  updateAwaitingTrial: async ({ dispatch }, data) => {
    const payload = { ...data };
    delete payload.id;
    const response = await httpService.patch(
      `inmate/awaiting-trial/${data.id}`,
      payload
    );
    dispatch("getAllAwaitingTrial");
    return response.data.message;
  },
  createConvicted: async ({ dispatch }, data) => {
    const response = await httpService.post(`inmate/convicted`, data);
    dispatch("getAllConvicted");
    return response.data.message;
  },
  getAllConvicted: async ({ commit }, options) => {
    const response = await httpService.get(`inmate/convicted`, {
      params: options,
    });
    commit("SET_CONVICTED", response.data.data);
  },
  getConvictedInmate: async (context, id) => {
    const response = await httpService.get(`inmate/convicted/${id}`);
    return new Inmate(response.data.data);
  },
  updateConvicted: async ({ dispatch }, data) => {
    const payload = { ...data };
    delete payload.id;
    const response = await httpService.patch(
      `inmate/convicted/${data.id}`,
      payload
    );
    dispatch("getAllConvicted");
    return response.data.message;
  },
};

const mutations = {
  SET_AWAITING_TRIAL: (state, inmates) => {
    const pagination = new Pagination(inmates, "inmates/getAllAwaitingTrial");
    pagination.result = pagination.result.map((inmate) => new Inmate(inmate));
    state.awaitingTrialList = pagination;
  },
  SET_CONVICTED: (state, inmates) => {
    const pagination = new Pagination(inmates, "inmates/getAllConvicted");
    pagination.result = pagination.result.map((inmate) => new Inmate(inmate));
    state.convictedList = pagination;
  },
  CLEAR: (state) => {
    state.awaitingTrialList = Pagination.prototype.createEmpty();
    state.convictedList = Pagination.prototype.createEmpty();
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
