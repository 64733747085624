import { State } from "./state";

export class ProsecutingAgency {
  constructor(agency) {
    this.id = agency.id;
    this.name = agency.name;
    this.label = agency.label;
    this.jurisdiction = agency.jurisdiction.charAt(0).toUpperCase() + agency.jurisdiction.slice(1);
    this.state = new State(agency.state);
    this.createdAt = new Date(agency.createdAt);
    this.updatedAt = new Date(agency.updatedAt);
  }

  get stateName() {
    return this.state.name;
  }
}
